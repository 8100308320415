@use '@/styles/utils/mixins.scss' as *;

.dropdownContainer {
  width: 100%;
  max-width: 25rem;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  overflow: hidden;
  margin-top: 3.75rem;
}

.companyTitle {
  padding: 1rem 0rem;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}
.toggleIcon {
  font-size: 1.5rem;
}

.employeeList {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.employeeItem {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  border: 0.063rem solid var(--Neutral-04, #d3d3d3);
  border-radius: 0.625rem;
}

.employeeName {
  font-size: 1rem;
  font-weight: bold;
}

.employeeDesignation {
  font-size: 0.875rem;
}
